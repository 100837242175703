// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";


import EmailVerificationController, {
  Props,
 } from "./EmailVerificationController.web";
import "./CustomForm.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    }
  },
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
  },
  overrides: {
    MuiButton: {
        root: {
          fontFamily: 'Museo Sans, sans-serif',
        }
    }
  }
});

// Customizable Area End

export default class OtpAuth extends EmailVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <Box style={{overflowX:"hidden"}}>
       <Header {...this.props} />
          <Dialog open={this.state.isPopupOpen}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <p>Your OTP has been resent successfully.</p>
          </DialogContent>
        </Dialog>
         <div className="mainContainer">

            <div
               style={{
                display:"flex",
                flexDirection:"row",
            
                justifyContent: "flex-start",
                alignItems:"flex-start",
                width: "100%",
                paddingLeft: "100px",
               }}
            >
                 <Button 
                        style={{
                          color:"black"
                        }}
                        onClick={this.goBack}
                        >
                          Back
               </Button>
            </div>
              
                <div 
                   style={{
                          fontWeight: 600,
                          fontSize: '2.5vw',
                          fontFamily: 'Museo Sans, sans-serif',
                          paddingBottom: "2vh",
                          
                   }}
                >
                    Authenticator
                    </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: '1.25vw',
                    fontFamily: 'Museo Sans, sans-serif',
                    paddingBottom: "35px",
                    
             }}
                >We have sent a 6 digit code to email {this.maskEmail(this.state.email)}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: '1.25vw',
                    fontFamily: 'Museo Sans, sans-serif',
                    paddingBottom: "35px",
                    
             }}
                > Enter Verification Code
                </div>
                <div className="otpContainer">
          {this.state.otp.map((digit, index) => (
            <input 
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength={1}
              value={digit !== null ? digit.toString() : ""}
              onChange={(e) => this.handleChange(index, e)}
              onKeyDown={(e) => this.handleKeyDown(index, e)}
              style={{
                width: "40px",
                height: "40px",
                fontSize: "18px",
                textAlign: "center",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginRight: "5px",
              }}
             
            />
          ))}
        </div>
                <div
                 style={{
                    display:"flex",
                    flexDirection:"row",
                    justifyContent: "center",
                    alignItems: "first baseline"
                 }}
                > 
                  <div
                  style={{
                    fontWeight: 400,
                    fontSize: '1.25vw',
                    fontFamily: 'Museo Sans, sans-serif',
                    paddingBottom: "10px",
                    color:'#a9a9a9'
             }}
                >  if you didn't recieve code !
                    </div>
                     
                    <button
                    style={{
                        background:'none',
                        border:'none',
                        color:'#70211F',
                        fontWeight: 600,
                        fontSize: '1.5vw',
                        fontFamily: 'Museo Sans, sans-serif',
                        height: '3.889vw',
                        cursor:"pointer"


                    }} 
                    onClick={this.handleResendOtp}>Resend</button>
                </div>
                
                {this.state.errorMessage && (
                  <div 
                  style={{ 
                    color: "red", 
                    marginBottom: "2vh", 
                    fontSize: "1.5vw",
                    fontWeight: 500,
                    fontFamily: 'Museo Sans, sans-serif',
                     }}>
    {this.state.errorMessage}
  </div>
)}
                <Button 
                         style={{
                          borderRadius: '0.556vw',
                          backgroundColor: '#70211F',
                          width: '15.5vw',
                          fontWeight: 600,
                          fontSize: '1.25vw',
                          fontFamily: 'Museo Sans, sans-serif',
                          height: '3.889vw',
                          color: 'white',
                          cursor: 'pointer',
                          textTransform: 'none',
                          opacity: this.isOtpValid() ? 1 : 0.6,
                    
                        }}
                        onClick={this.onVerifyOtp}
                        disabled={!this.isOtpValid()}

                        >
                          Authenticate
               </Button>
               </div>
            
                
         
        <div style={{bottom: 0}}>
        <NavigationMenu {...this.props} />
        </div>
        
       </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}



// Customizable Area End
