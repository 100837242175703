import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import storage from "../../../framework/src/StorageProvider.web";
import { State } from 'country-state-city';
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: { navigate: (screen: string, params?: object) => void };
  id: string;
  // Customizable Area End
}

// Customizable Area Start





// Customizable Area End

interface S {
  // Customizable Area Start
  email:string;
  otp:(number | null)[]
  type:string;
  isPopupOpen:boolean;
  errorMessage:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class EmailVerificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addUserApiCallId: string = "";
  apiLoginCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email:"",
      otp:[null,null,null,null,null,null],
      type: "",
      isPopupOpen: false,
      errorMessage: ""
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson){
        if (responseJson.error) {
          if(responseJson.error.otp){
            this.setState({ errorMessage: responseJson.error.otp });
          }else{
            this.setState({ errorMessage:responseJson.error });
          }
          
        }
      
       else if (responseJson.data?.id) {
          this.setState({ errorMessage: "" });
          window.localStorage.setItem("userId", responseJson.data.id);
          window.localStorage.setItem("signupToken",responseJson.meta?.token);
          if(this.state.type!=="signup"){
            window.localStorage.setItem("token",responseJson.meta?.token);
          }

          storage.set("businessSignupInfo", "");

          this.goTohelpUsSignUp();
          
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const email =  await window.localStorage.getItem("email")|| ""
    this.setState({email:email})
    const type =  await window.localStorage.getItem("type")|| ""
    this.setState({type:type})

    
 }


 handleChange = (index:number, event: React.ChangeEvent<HTMLInputElement>)=>{
    const {value} = event.target;
    const newOtp =[...this.state.otp];
    newOtp[index] = value ? parseInt(value,10) : null;
    
    this.setState({ otp: newOtp }, () => {
      if (value) {
        
        
        if (index < this.state.otp.length - 1) {
          const nextInput = document.getElementById(`otp-${index + 1}`) as HTMLInputElement;
          nextInput?.focus();
        }
      }
    });
    
  };

    
 
 handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Backspace" && this.state.otp[index] === null && index > 0) {
    const prevInput = document.getElementById(`otp-${index - 1}`) as HTMLInputElement;
    prevInput?.focus();
  }
};

isOtpValid = (): boolean => {
  return this.state.otp.every((digit) => digit !== null && !isNaN(digit));
};
  onResendOtp =  () => {
    

    const header = {
    };
    
    const formData = new FormData();
    formData.append("email", this.state.email);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendotp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onVerifyOtp =  () => {
    const otpCode = this.state.otp.map((digit) => digit ?? "").join("");

    const header = {
    };
    
    const formData = new FormData();
    formData.append("pin", otpCode);
    formData.append("type",this.state.type)


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.verifyotp
    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  
    maskEmail = (email: string): string => {
    const [username, domain] = email.split("@");
  
    if (username.length <= 2) {
      return `${username[0]}***@${domain}`; 
    }
  
    const maskedUsername = username[0] + "*".repeat(username.length - 2) + username[username.length - 1];
  
    return `${maskedUsername}@${domain}`;
  };
  
  goBack(){
    window.history.back()
  }
    
  goTohelpUsSignUp = () => {

    if(this.state.type=="signup"){
      const msg = new Message(getName(MessageEnum.NavigationMessage));
    
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionPlans"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }else{
       this.props.navigation.navigate("CustomisableBusinessProfile")
    }
    
    
  }
  
  handleOpenPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  
  handleClosePopup = () => {
    this.setState({ isPopupOpen: false });
  };

      
  handleResendOtp = () => {
    this.setState({errorMessage:""})
    this.onResendOtp(); // ✅ Call actual resend function
    this.setState({ isPopupOpen: true }); // ✅ Show popup

    // ✅ Auto-close popup after 2 seconds
    setTimeout(() => {
      this.setState({ isPopupOpen: false });
    }, 500);
  };
    

 

  // Customizable Area End
}
