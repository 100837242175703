import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface Feature {
  
  
}

export interface SubscriptionPlan {
  value: string; 
  plan_id:string;
}

interface SubscriptionAttributes {
  name: string;
  color: string;
  subscription_plans: SubscriptionPlan[];
  key:string;
  most_popular:boolean;
  description:string;
  status:string;
  features: Feature;
  
 
}

export interface Subscription {
  id: string;
  type: string;
  attributes: SubscriptionAttributes;
}
export interface Touched {
  card_name: boolean;
  creditcard_num: boolean;
  expire_date: boolean;
  cvv_num: boolean;
}
export interface Error {
  card_name: string;
  creditcard_num: string;
  expire_date: string;
  cvv_num: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: Subscription[];
  data: any;
  selectedSubscription:Subscription|null;
  email_address: string;
  card_name: string;
  creditcard_num: string;
  expire_date: string;
  cvv_num: string;
  billingPeriod:string;
  showPassword:boolean;
  plan_id: string;
  product_id:string;
  subscriptionPlan: string;
  paymentFailed:boolean;
  paymentSuccess:boolean;
  successData:any;
  loading: boolean;
  validFrom:string;
  loadingStart:boolean;
  hasActiveSubscription:boolean;
  billing_month:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListCallId: any;
  getSelectedSubsCallId : string = "";
  createPaymentApiCallId : string = "";
  getSubscriptionCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      selectedSubscription:null,
      email_address: "",
      card_name: "",
      creditcard_num: "",
      expire_date: "",
      cvv_num: "",
      billingPeriod:"",
      showPassword:false,
      plan_id: "",
      product_id:"",
      subscriptionPlan:"",
      paymentFailed:false,
      paymentSuccess:false,
      successData:[],
      loading:false,
      validFrom:'',
      loadingStart:false,
      hasActiveSubscription:false,
      billing_month:"",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const restApiResponseId = getName(MessageEnum.RestAPIResponceMessage);

      if (message.id === restApiResponseId &&
        this.getListCallId !== "" &&
        this.getListCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            this.setState({
              subscriptions: responseJson.Subscription.data,
            });
            const hasActive = responseJson.Subscription.data.some(
              (subscription:any) => subscription.attributes.status === "ACTIVE"
            );
            this.setState({ hasActiveSubscription: hasActive });
            this.setState({loadingStart:false})
      }
      else if (message.id === restApiResponseId &&
        this.getSelectedSubsCallId !== "" &&
        this.getSelectedSubsCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            this.setState({ selectedSubscription: responseJson.Subscription.data, email_address:responseJson.current_user, product_id: responseJson.Subscription.data.attributes.product_id }, () => {
              this.props.navigation.navigate("SubscriptionPlans", { id: responseJson.Subscription.data.id });
              const element = document.getElementById('selected-subscription-form');
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              }
            });
      }
      else if
      (message.id === restApiResponseId &&
        this.getSubscriptionCallId !== "" &&
        this.getSubscriptionCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({subscriptionPlan:"You got Free Subscription!"})        
      } 
      else if
      (message.id === restApiResponseId &&
        this.createPaymentApiCallId !== "" &&
        this.createPaymentApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            if(responseJson && responseJson.recurring_subscription){
              this.setState({loading:false})
              this.setState({paymentSuccess:true});
              this.setState({successData:responseJson.recurring_subscription})
              this.setState({validFrom:responseJson.valid_from})
              const element = document.getElementById('selected-subscription-success');
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              }
            }else{
              this.setState({ loading: false, paymentFailed: true, billingPeriod:"" }, () => {
                const element = document.getElementById('selected-subscription-failed');
                if (element) {
                  element.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              });
            }
      } 
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    
    const lastPathSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (lastPathSegment !== 'SubscriptionPlans') {
      let article_Id = Number(lastPathSegment); 
      this.selectedSubscription(article_Id,"")
    }
    // Customizable Area End
  }


  // componentDidUpdate() {
  //   if (this.state.selectedSubscription || this.state.paymentSuccess || this.state.paymentFailed) {
      
  //   }
  // }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    this.setState({loadingStart:true})
    const currentUserId = window.localStorage.getItem("userId");
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubscriptionAPiEndPoint}?current_user_id=${currentUserId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  handleBillingAmount = (value:string) =>{
    this.setState({billing_month:value})
  }
  gotoSubDetailScreen(item: any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionDetails"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
    // Customizable Area End
  }

  // Customizable Area Start

  goToSelectedSubPage = () =>{
    this.setState({paymentFailed:false})
  }

  handleClickShowPass = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  handleMouseDownPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  selectedSubscription = (id:number, name:string) =>{
    const currentUserId = window.localStorage.getItem("userId");

    if(name === "THE HOUSE POUR"){
      
      if(this.state.hasActiveSubscription){
        this.props.navigation.navigate("CustomisableBusinessProfile")
      }else{
        this.goTohelpUsSignUp();
      }
      this.getFreeSubscription();
      
    }else{
      const header = {
        token: this.state.token,
        "Content-Type": configJSON.subscriptionApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getSelectedSubsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSelectedSubscriptionApiEndPoint}/${id}?current_user_id=${currentUserId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getSubscriptionAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  goToNextPage = () =>{
    
    if(this.state.hasActiveSubscription){
      this.props.navigation.navigate("CustomisableBusinessProfile")
    }else{
      this.goTohelpUsSignUp();
    }
    
  }

  goTohelpUsSignUp = () => {

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessSignupForm1"
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setSignUpFields = (key: string, value: string | boolean | File) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  };

  handleBillingChange = (value: string) => {
    this.setState({billingPeriod: value});
    this.setState({plan_id:value})
  };

  handleClick = () => {

    this.setState({loading:true})

    let Token =   window.localStorage.getItem("signupToken") ||  window.localStorage.getItem("token");

    const header = {
      token:Token
    };
    const formData = new FormData();

    formData.append('email_address', this.state.email_address);
    formData.append("name_of_card", this.state.card_name);
    formData.append('card_number', this.state.creditcard_num);
    formData.append('expire_date', this.state.expire_date);
    formData.append('cvv', this.state.cvv_num);
    formData.append('plan_price', this.state.plan_id);
    formData.append('product_id', this.state.product_id);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createPaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPayment
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail 
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  getFreeSubscription = () =>{

    const accountId = window.localStorage.getItem("userId");
    const Token = window.localStorage.getItem("token") || window.localStorage.getItem("signupToken");

    const freeSubsData = {
      recurring_subscription: {
        account_id: accountId,
        name: "THE HOUSE POUR",
        subscription_id: 1,
        status: "active",
        billing_frequency: "month"
      }
    };

    const header = {
      token: Token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(freeSubsData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFreeSubscription
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  goToSubscriptionPage = () =>{
  this.props.navigation.goBack()
  this.setState({selectedSubscription:null})
  this.fetchSubscriptionsList()
  }


  goBack(){
    window.history.back()
  }
  // Customizable Area End
}
