// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Header from "../../navigationmenu/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";


import EmailVerificationController, {
  Props,
 } from "./EmailVerificationController.web";
import "./CustomForm.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    }
  },
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
  },
  overrides: {
    MuiButton: {
        root: {
          fontFamily: 'Museo Sans, sans-serif',
        }
    }
  }
});

// Customizable Area End

export default class EmailVerification extends EmailVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
       <Box style={{overflowX:"hidden"}}>
        <Header {...this.props} />
            
          
         <div className="mainContainer1">

            <div
              
            >
                 <Button 
                        className="backButton"
                        >
                          Back
               </Button>
            </div>
              
                <div 
                   style={{
                          fontWeight: 600,
                          fontSize: '2.5vw',
                          fontFamily: 'Museo Sans, sans-serif',
                          paddingBottom: "2vh",
                          
                   }}
                >
                    Verify your Email
                    </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: '1.25vw',
                    fontFamily: 'Museo Sans, sans-serif',
                    paddingBottom: "35px",
             }}
                >We have sent a verification email to {this.state.email}
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: '1.5vw',
                    fontFamily: 'Museo Sans, sans-serif',
                    paddingBottom: "10px",
             }}
                >
                    Didn't recieve the email? Check spam or promotion folder or</div>
                <Button 
                         style={{
                          borderRadius: '0.556vw',
                          backgroundColor: '#70211F',
                          width: '15.5vw',
                          fontWeight: 600,
                          fontSize: '1.25vw',
                          fontFamily: 'Museo Sans, sans-serif',
                          height: '3.889vw',
                          color: 'white',
                          cursor: 'pointer',
                          textTransform: 'none'
                    
                        }}
                        onClick={this.onResendOtp}

                        >
                          Resend Email
               </Button>
               </div>
            
                
         
        <div style={{bottom: 0}}>
        <Footer {...this.props} />
        </div>
        
       </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}



// Customizable Area End
