import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography , TextField, CircularProgress, Backdrop, Button} from "@material-ui/core";
import { Box, Grid, Paper, Radio, RadioGroup, FormControlLabel,  InputAdornment, IconButton} from "@mui/material";
import Visibility from '@material-ui/icons/Visibility';
import { DateField } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import { backgroundImg, detailIcon, imageFailed, imageSuccess,backBtnTandC } from "./assets";
import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Touched, Error, Subscription } from "./CustomisableusersubscriptionsController"
import Divider from '@mui/material/Divider';



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
      "fontFamily": `"Museo Sans", sans-serif`,
  }
});

// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      card_name: Yup.string().required('Name is required'),
      creditcard_num: Yup.string()
        .min(16, 'Card number must be of 16 numbers')
        .max(16, 'Card number must be of 16 numbers')
        .matches(/^[0-9\s]{16,19}$/, "Invalid credit card number")
        .required('Card number is required'),
      billing_month: Yup.string().required('Billing month is required'),
      expire_date: Yup.string()
      .required("Expiration date is required")
      .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Invalid date format (MM/YY)")
      .test("expirationDate", "Card has expired", (value) => {
        if (!value) return false;
        const [month, year] = value.split("/").map(Number);
        const expiryDate = dayjs()
          .year(2000 + year) 
          .month(month - 1) 
          .endOf("month");
        
        return dayjs().isBefore(expiryDate);
      }),
      cvv_num: Yup.string()
        .length(3, 'CVV must be 3 digits')
        .matches(/^\d{3}$/, 'CVV must contain only numbers')
        .required('CVV is required'),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "-22px", fontSize: "12px", color: "#f94b4b",textAlign:"right" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };
  // Customizable Area End

  render() {
    const {subscriptions, selectedSubscription, paymentFailed, paymentSuccess, successData, loading, validFrom,loadingStart} = this.state;
    
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{ overflowX: "hidden" }}>
          <Header {...this.props} />
         
          {!subscriptions || subscriptions.length === 0 ? (
            <Box style={webStyle.spinnerContainer}>
              <CircularProgress color="inherit" size={30} />
            </Box>
          ) : (
            (subscriptions && subscriptions.length > 0 && !selectedSubscription && !paymentFailed && !paymentSuccess) &&
            
            <Box sx={webStyle.productContainer}>
               <div
               style={{
                display:"flex",
                flexDirection:"row",
            
                justifyContent: "flex-start",
                alignItems:"flex-start",
                width: "100%",
                paddingLeft: "8vw",
               }}
            >
                 <Button 
                        className="backButton"
                        onClick={this.goBack}
                        >
                          Back
               </Button>
            </div>
              <Typography style={webStyle.heading}>Choose your Subscription</Typography>
              <Grid item xs={12}>
                <Grid container spacing={4} sx={{ justifyContent: 'center', alignItems:'center' }}>
                  {subscriptions.map((subscription: Subscription) => (
                    <Grid key={subscription.attributes.key} item >
                       {subscription.attributes.most_popular &&(
                        <Box
                          style={{
                            display: 'flex',
                            backgroundColor: subscription.attributes.color,
                            color: 'white',
                            fontSize:'20px',
                            fontWeight: 700,
                            justifyContent: 'center',
                            borderRadius:"16px 16px 0px 0px ",
                            padding:'15px',

                          }}
                        >
                           MOST POPULAR
                        </Box>
                      )}
                     <Paper
                       sx={{
                            border: subscription.attributes.most_popular === false
                          ? `2px solid ${subscription.attributes.color}`
                          : `6px solid ${subscription.attributes.color}`,
                          borderRadius: subscription.attributes.most_popular === false
                          ? '16px'
                       : '0px 0px 16px 16px',
                       height: subscription.attributes.most_popular === false ? 550 : 600,
    
                     ...webStyle.paper,
                                  }}
                      >                     
                        <div></div>
                        <Typography style={webStyle.name}>{subscription.attributes.name}</Typography>
                        <Typography style={{ color: subscription.attributes.color, ...webStyle.amountMonth }}>{(subscription.attributes.name === "THE HOUSE POUR") ? "Free" : subscription.attributes.subscription_plans[0]?.value}</Typography>
                        <Typography style={webStyle.amountYear}>{subscription.attributes.subscription_plans[1]?.value}{(subscription.attributes.name !== "THE HOUSE POUR") ? "*" : ""}</Typography>
                        <Typography style={webStyle.features}>{subscription.attributes.description}</Typography>
              
                        <Box style={{flexGrow:1, ...webStyle.padding}}>
                         {Object.entries(subscription.attributes.features).map(([key, value]) => (
                           <Box style={webStyle.detailIconWrapper} key={key}>                              
                                  <Typography style={webStyle.features}>
                                    {value === true && <img src={detailIcon} alt="icon" />} 
                                    {typeof value !== 'boolean' ? value  : ""}
                                    {" "}
                                    {key}
                                  </Typography>
                              </Box>
                          ))}
                        </Box>
                        <Box>
                        <Button style={{ backgroundColor: subscription.attributes.color, ...webStyle.selectBtn }} disabled={!(subscription.attributes?.status === "SELECT")} data-test-id="selectBtnId" onClick={() => this.selectedSubscription(Number(subscription.id),subscription.attributes.key)} >{subscription.attributes?.status}</Button>
                        </Box>
                          
                      </Paper>
                      
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          )
          }
          {
            selectedSubscription && !paymentFailed && !paymentSuccess &&
            <Box style={webStyle.background}>
              <Box style={webStyle.mainContainer} id="selected-subscription-form">
                <Box style={webStyle.formContainer} >
                  <Formik
                    data-test-id="formik2"
                    initialValues={{
                      card_name: this.state.card_name,
                      creditcard_num: this.state.creditcard_num,
                      expire_date: this.state.expire_date,
                      cvv_num: this.state.cvv_num,
                      billing_month: this.state.billingPeriod,
                    }}
                    enableReinitialize={true}
                    onSubmit={(values) => { this.handleClick(); }}
                    validationSchema={this.userSchema}
                  >
                    {({ errors, touched, setFieldValue, handleSubmit }) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <Grid item xs={12}  >
                          <Grid container justifyContent="center">
                            <Grid item >
                              <Box style={{ padding: "24px 64px" }}>
                                <Button className="btn-container-terms" data-test-id="gotoBtn" onClick={this.goToSubscriptionPage} >
                                  <img src={backBtnTandC}></img>
                                </Button>
                                <Typography style={webStyle.headingStyle}>Final step, make the payment</Typography>
                                <Typography style={webStyle.selectedDesc}>To finalize your subscription, kindly complete the payment details</Typography>
                                <Box style={webStyle.formWrapper}>
                                  <Box style={webStyle.txtFieldStyle}>
                                    <Box className="textfield-error-wrapper">
                                      <Typography style={webStyle.detailHeading}>Email Address</Typography>
                                      <TextField
                                        variant="outlined"
                                        margin="none"
                                        size="small"
                                        disabled
                                        style={webStyle.textfieldMargin}
                                        className="formInputStyle"
                                        data-test-id={"email_address"}
                                        value={this.state.email_address}
                                        placeholder="Ex. abc@abc.com"
                                        fullWidth
                                        name="email_address"
                                      />
                                    </Box>
                                  </Box>
                                  <Box style={webStyle.txtFieldStyle}>
                                    <Box className="textfield-error-wrapper">
                                      <Typography style={webStyle.detailHeading}>Billing Period</Typography>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="f"
                                        data-test-id={"billing_period"}
                                        name="radio-buttons-group"
                                        style={webStyle.radioGroup}
                                        onChange={(event) => {
                                          const isMonthly = event.target.value.includes("Month");
                                          setFieldValue("billing_month", event.target.value); 
                                          this.handleBillingAmount(event.target.value)
                                          const selectedPlanId = isMonthly 
                                            ? selectedSubscription.attributes?.subscription_plans[0]?.plan_id 
                                            : selectedSubscription.attributes?.subscription_plans[1]?.plan_id;
                                      
                                          this.handleBillingChange(selectedPlanId);
                                        }}
                                        >
                                        <FormControlLabel
                                          value={`Monthly ${selectedSubscription.attributes?.subscription_plans[0].value}`}
                                          control={<Radio
                                            style={webStyle.radioBox1} />}
                                          label={`Monthly (${selectedSubscription.attributes?.subscription_plans[0].value} )`}
                                          style={webStyle.radioBtnTextStyle}
                                        />
                                        <FormControlLabel
                                          value={`Yearly ${selectedSubscription.attributes?.subscription_plans[1].value}`}
                                          control={<Radio
                                            style={webStyle.radioBox1} />}
                                          label={`Yearly (${selectedSubscription.attributes?.subscription_plans[1].value} )`}
                                          style={webStyle.radioBtnTextStyle}
                                        />
                                      </RadioGroup>
                                      {this.getErrorMessage(touched, errors, "billing_month")}
                                    </Box>
                                  </Box>
                                  <Box style={webStyle.txtFieldStyle}>
                                    <Box className="textfield-error-wrapper">
                                      <Typography style={webStyle.detailHeading}>Name on Card</Typography>
                                      <TextField
                                        type="text"
                                        variant="outlined"
                                        margin="none"
                                        placeholder="Ex. Max Goodwin"
                                        size="small"
                                        style={webStyle.textfieldMargin}
                                        className="formInputStyle"
                                        value={this.state.card_name}
                                        data-test-id={"card_name"}
                                        fullWidth
                                        name="card_name"
                                        onChange={(event) => {
                                          this.setSignUpFields("card_name", event.target.value);
                                          setFieldValue("card_name", event.target.value);
                                        }}
                                      />
                                      {this.getErrorMessage(touched, errors, "card_name")}
                                    </Box>
                                  </Box>
                                  <Box style={webStyle.txtFieldStyle}>
                                    <Box className="textfield-error-wrapper">
                                      <Typography style={webStyle.detailHeading}>Credit Card Number</Typography>
                                      <TextField
                                        variant="outlined"
                                        margin="none"
                                        placeholder="Ex. 0000 0000 0000 0000"
                                        className="formInputStyle"
                                        data-test-id={"creditcard_num"}
                                        inputProps={{ maxLength: 16 }}
                                        value={this.state.creditcard_num}
                                        size="small"
                                        style={webStyle.textfieldMargin}
                                        fullWidth
                                        name="creditcard_num"
                                        onChange={(event) => {
                                          this.setSignUpFields("creditcard_num", event.target.value);
                                          setFieldValue("creditcard_num", event.target.value);
                                        }}
                                      />
                                      {this.getErrorMessage(touched, errors, "creditcard_num")}
                                    </Box>
                                  </Box>
                                  <Box style={webStyle.textWrapper}>
                                    <Box className="textfield-error-wrapper">
                                      <Typography style={webStyle.detailHeading}>Expiry Date</Typography>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateField
                                          data-test-id="expire_dateId"
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                borderColor: '#732120',
                                              },
                                              '& .MuiOutlinedInput-input': {
                                                padding: '10.5px 14px',
                                                height:'19px'
                                              },
                                              '&:hover fieldset': {
                                                borderColor: '#732120',
                                              },
                                              '&.Mui-focused fieldset': {
                                                borderColor: '#732120',
                                              },
                                            },
                                          }}
                                          size="small"
                                          format="MM/YY"
                                          name="expire_date"
                                          onChange={(newValue: any) => {
                                            const dateValue = newValue ? newValue.format('MM/YY') : '';
                                            this.setSignUpFields("expire_date", dateValue);
                                            setFieldValue("expire_date", dateValue);
                                          }}
                                        />
                                      </LocalizationProvider>
                                      <Box style={{ marginTop: "22px" }}>
                                        {this.getErrorMessage(touched, errors, "expire_date")}
                                      </Box>
                                    </Box>
                                    <Box className="textfield-error-wrapper">
                                      <Typography style={webStyle.detailHeading}>CVV</Typography>
                                      <TextField
                                        variant="outlined"
                                        margin="none"
                                        placeholder="123"
                                        size="small"
                                        type={this.state.showPassword ? "text" : "password"}
                                        style={webStyle.textfieldMargin}
                                        inputProps={{ maxLength: 3 }}
                                        className="textStyle"
                                        value={this.state.cvv_num}
                                        data-test-id={"cvv_num"}
                                        fullWidth
                                        name="cvv_num"
                                        onChange={(event) => {
                                          this.setSignUpFields("cvv_num", event.target.value);
                                          setFieldValue("cvv_num", event.target.value);
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPass}
                                                onMouseDown={this.handleMouseDownPassword}
                                              >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                      {this.getErrorMessage(touched, errors, "cvv_num")}
                                    </Box>
                                  </Box>
                                  <Box style={{ marginTop: '8px' }}>
                                    <Button
                                      type="submit"
                                      color="inherit"
                                      data-test-id={"SignupBtn"}
                                      style={webStyle.btnStyle}
                                    >
                                      Pay {this.state.billing_month} and upgrade
                                    </Button>
                                  </Box>
                                  <Box className="spinnerContainer">
                                    <Backdrop
                                      open={loading}
                                      style={{ color: '#fff', zIndex: 1000 }}
                                    >
                                      <CircularProgress color="inherit" size={30} />
                                    </Backdrop>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid item >
                              <Box style={{ padding: "24px 64px" }}>
                                <Typography style={webStyle.selectedStyle}>Selected subscription </Typography>
                                <Typography style={webStyle.selectedDesc}>You'll pay for</Typography>
                                <Paper sx={{ border: `2px solid ${selectedSubscription.attributes?.color}`, ...webStyle.paper2 }} >
                                <Typography style={webStyle.name}>{selectedSubscription.attributes.name}</Typography>
                                <Typography style={{ color: selectedSubscription.attributes.color, ...webStyle.amountMonth }}>{(selectedSubscription.attributes.name === "THE HOUSE POUR") ? "Free" : selectedSubscription.attributes.subscription_plans[0]?.value}</Typography>
                                <Typography style={webStyle.amountYear}>{selectedSubscription.attributes.subscription_plans[1]?.value}{(selectedSubscription.attributes.name !== "THE HOUSE POUR") ? "*" : ""}</Typography>
                                <Typography style={webStyle.features}>{selectedSubscription.attributes.description}</Typography>
                                 
                                   <Box style={{flexGrow:1, ...webStyle.padding}}>
                         {Object.entries(selectedSubscription.attributes.features).map(([key, value]) => (
                           <Box style={webStyle.detailIconWrapper} key={key}>                              
                                  <Typography style={webStyle.features}>
                                    {value === true && <img src={detailIcon} alt="icon" />} 
                                    {typeof value !== 'boolean' ? value  : ""}
                                    {" "}
                                    {key}
                                  </Typography>
                              </Box>
                          ))}
                        </Box>
                                </Paper>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          }
          <>
            {paymentSuccess && (
              <Box style={webStyle.background} id="selected-subscription-success">
                <Box style={webStyle.failedPaperWrapper}>
                  <Paper sx={webStyle.failedPaper}>
                    <img src={imageSuccess} alt="Payment Success" />
                    <Typography style={webStyle.successHeading}>Payment Success!</Typography>
                    <Typography style={webStyle.faileDesc}>
                      Invoice sent to your email address
                    </Typography>
                    <Divider style={webStyle.divider} />
                    <Typography style={webStyle.upgradedHeading}>Upgraded to</Typography>
                    <Typography style={webStyle.successName}>
                      {successData?.name}
                    </Typography>
                    <Typography style={webStyle.successValidity}>
                      {validFrom}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "24px",
                      }}
                    >
                      
                      <Button
                        style={{ ...webStyle.failedBtn, ...webStyle.goToProfile }}
                        color="inherit"
                        onClick={this.goToNextPage}
                      >
                        Next Step
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
            {paymentFailed && (
              <Box style={webStyle.background} id="selected-subscription-failed">
                <Box style={webStyle.failedPaperWrapper}>
                  <Paper sx={webStyle.failedPaper}>
                    <img src={imageFailed} alt="Payment Failed" />
                    <Typography style={webStyle.failedHeading}>
                      Payment Failed!
                    </Typography>
                    <Typography style={webStyle.faileDesc}>
                      Failed due to a technical issue at the payment gateway
                    </Typography>
                    <Divider style={webStyle.divider} />
                    <Box
                      style={{
                        gap: "24px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        style={{ ...webStyle.failedBtn, ...webStyle.tryAgain }}
                        onClick={this.goToSelectedSubPage}
                      >
                        Try Again
                      </Button>
                      <Button
                        style={{ ...webStyle.failedBtn, ...webStyle.goToProfile }}
                        onClick={this.goToNextPage}
                      >
                        Next Step
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
          </>
          <NavigationMenu {...this.props} />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  upgradedHeading:{
    fontWeight:500,
    fontSize:'18px'
  },
  successName:{
    color:'#9C0E2E',
    fontSize:'24px',
    fontWeight:700,
    margin:'14px 0px 24px 0px'
  },
  successValidity:{
    fontWeight:500,
    fontSize:'16px'
  },
  tryAgain:{
    border:'1.5px solid #712220',
    color:'#712220'
  },
  goToProfile:{
    backgroundColor:'#712220',
    color:'#FFFFFF',
  },
  failedPaperWrapper:{
    width:"100%",
    display:'flex',
    justifyContent:'center',
  },
  failedPaper:{
    width:'658px',
    textAlign:'center',
    padding:'64px 0px 64px 0px',
    margin:'165px 0px 506px 0px',
    borderRadius:'16px'
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    height:'100vh'
  },
  divider:{
    margin:'24px 0px'
  },
  failedHeading:{
    color:'#DC4242',
    fontSize:'32px',
    fontWeight:600,
    margin:'16px 0px 8px 0px'
  },
  successHeading:{
    color:'#059669',
    fontSize:'32px',
    fontWeight:600,
    margin:'16px 0px 8px 0px'
  },
  faileDesc:{
    color:'#808080',
    fontSize:'16px',
    fontWeight:500
  },
  failedBtn: {
    width: '164px',
    height: '45px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none' as const, 
  },  
  productContainer: {
    margin:"124px 0",
    textAlign:'center',
  },
  heading: {
    fontWeight: 600,
    fontSize:'32px',
    marginBottom: "72px",
  },
  name: {
    fontWeight:700,
    fontSize:'14px',
    letterSpacing:'2px',
  },
  selectBtn: {
    width:105,
    height:44,
    color:'#FFF',
    fontWeight:700,
    fontSize:'16px',
    borderRadius:'8px',
    marginTop:'24px'
  },
  amountMonth: {
    fontWeight:700,
    fontSize:'36px',
  },
  amountYear:{
    fontSize:'14px',
    fontWeight: 600,
    marginBottom: '15px',

  },
  textfieldMargin:{
    marginBottom:'24px'
  },
  padding: {
    padding:'15px 11px',
  },
  textWrapper:{
    display:'flex',
    gap:'14px'
  },
  features:{
    fontSize:"15px",
    fontWeight:300,   
    color:'#181818',
    lineHeight:1,
    marginBottom:'15px'
  },
  detailHeading:{
    fontWeight:600,
    fontSize:'16px',
    marginBottom:'8px'
  },
  detailIconWrapper:{
    display: "flex",
    alignItems: 'baseline',
    justifyContent:'center'
  },
  paper:{
    
    width: 286,
    padding: '29px 0px',
    backgroundColor: '#fff',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between'
    
    
  },
  paper2:{
    height: 479,
    width: 286,
    borderRadius:'16px',
    padding: '29px 0px',
    backgroundColor: '#fff',
    textAlign:'center'
  },
  selectedStyle:{
    fontSize:'24px',
    fontWeight:600,
  },
  selectedDesc:{
    fontSize:'16px',
    fontWeight:500,
    color:'#808080',
    margin: '8px 0px 32px 0px'
  },
  headingStyle:{
    fontSize:'32px',
    fontWeight:600,
  },
  mainContainer:{
    width:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  formWrapper:{
    width:'374px'
  },
  background:{
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: "flex",
  },
  formContainer: {
    backgroundColor: "#fff",
    margin: "144px 64px 206px 64px ",
    padding:"40px 0px",
    marginBottom: "206px",
    borderRadius:"8px",
    boxShadow: '0px 8px 24px 0px #00000014',
    border:'1px solid black',
  },
  subsGrid: {
    padding: '0px 80px 0px 80px'
  },
  txtFieldStyle: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
    fontFamily: 'Museo Sans", sans-serif',
  },
  radioBox1: {
    color: "#712220",
    padding:"0px 2px 0px 0px",
    fontSize: "16px",
  },
  radioBtnTextStyle: {
    lineHeight: "18px",
    fontSize: "16px",
    marginLeft: "0px",
    marginRight:'0px',
    color: "#712220",
  },
  radioGroup:{
    display:'flex',
    justifyContent:'space-between',
    marginBottom:'24px'
  },
  btnStyle: {
    backgroundColor: '#70211F',
    width: '100%',
    height: '45px',  
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '8px', 
    color: '#fff',
    textTransform: 'none' as const,
  }
  
};
// Customizable Area End
